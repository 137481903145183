<template>
  <div class="wrap">
    <!-- <van-nav-bar title="网上办事" /> -->

    <!-- 列表 -->
    <div class="special">
      <van-cell
        :key="index"
        is-link
        v-for="(item, index) in NWKindList"
        @click="goWork(item)"
      >
        <!-- :to="'/work/' + item.NWKId" -->
        <img :src="item.AppletIconUrl" class="linkicon" />{{
          item.Name
        }}</van-cell
      >
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { WeGetNWKindList } from "@/api/Network";

export default {
  components: {},
  data() {
    return {
      NWKindList: [],
    };
  },
  created() {
    this.getNWKindList();
  },
  methods: {
    goWork(scop) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../home/homeList/index?NWKId=" + scop.NWKId,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 获取办事类型列表
    getNWKindList() {
      WeGetNWKindList().then((res) => {
        this.NWKindList = res.data.data;
      });
    },
  },
};
</script>

<style>
.special {
  padding: 10px 10px;
}
.special img {
  position: relative;
  top: 30%;
  margin-top: -10px;
  width: 24px;
  height: 24px;
}
</style>